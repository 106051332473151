import React from "react";

const IconsListOrizontalIcon = ({children, title, icon, size = ""}) => (
  <div className={`c-icons-list-o__single-container col-12 col-lg-4 mb-5 mb-lg-0 ${size}`}>
    <div className={`c-icons-list-o__single-icon ${icon}`} />
    <div className='c-icons-list-o__single-right-container'>
      <div className='c-icons-list-o__single-title'>
        <h4>{title}</h4>
      </div>
      <div className='c-icons-list-o__single-text'>{children}</div>
    </div>
  </div>
);

export default IconsListOrizontalIcon;
