import React, {Component} from "react";
import Flickity from "react-flickity-component";

class BoxImageSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {loaded: false};
  }

  componentDidMount() {
    this.setState({loaded: true});
  }

  render() {
    const {children, title} = this.props;
    if (this.state.loaded === false) {
      return <></>;
    }

    return (
      <div className={`c-box-image-slider ${this.props.customClass !== undefined ? this.props.customClass : ""}`}>
        <div className='c-box-image-slider__header'>
          <h3>{title}</h3>
          <div className='c-box-image-slider__header-arrow-container'>
            <span className='c-box-image-slider__header-arrow' onClick={this.prev} onKeyDown={this.prev} role='button' tabIndex={0} aria-label='Immagine precedente'>
              <i>Immagine precedente</i>
            </span>
            <span className='c-box-image-slider__header-arrow right' onClick={this.next} onKeyDown={this.next} role='button' tabIndex={0} aria-label='Immagine successiva'>
              <i>Immagine sucessiva</i>
            </span>
          </div>
        </div>
        <Flickity
          flickityRef={c => (this.flkty = c)}
          options={{
            draggable: false,
            prevNextButtons: false,
            pageDots: false,
            wrapAround: true,
            autoPlay: true
          }}
        >
          {children}
        </Flickity>
      </div>
    );
  }

  next = () => {
    this.flkty.next();
  };

  prev = () => {
    this.flkty.previous();
  };
}

export default BoxImageSlider;
