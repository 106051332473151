import React from "react";

const ImageFull = ({children, image = ""}) => {
  console.log(image);
  return (
    <div className='c-image-full' style={{backgroundImage: `url(${image})`}}>
      <div className='container'>
        <div className='row'>
          <div className='col-12 offset-lg-3 col-lg-6'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ImageFull;
