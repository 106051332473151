import React from "react";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import Herobanner from "../components/herobanner";
import Button from "../components/elements/button";
import Container from "../components/containers/container";
import Box from "../components/containers/box";
import IconMiniTitle from "../components/elements/iconMiniTitle";
import ImageFull from "../components/imageFull";
import IconsListOrizontal from "../components/icons-list/orizontal/IconsListOrizontal";
import IconsListOrizontalIcon from "../components/icons-list/orizontal/iconsListOrizontalIcon";
import IconsListVertical from "../components/icons-list/vertical/iconsListVertical";
import IconsListVerticalIcon from "../components/icons-list/vertical/iconsListVerticalIcon";
import BoxImageSlider from "../components/boxImageSlider";

import ContactContainer from "../components/elements/contactContainer";

const IndexPage = () => (
  <Layout>
    <Helmet>
      <title>Elettronica e automazione industriale</title>
      <meta
        name='description'
        content='Ci occupiamo di elettronica e automazione industriale applicata a vari settori produttivi (tessile, meccanotessile, metalmeccanico, fondiario, packaging, trattamento acque e così via), e fino ad oggi contiamo più di 2000 installazioni in tutto il mondo.'
      />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='' />
      <meta property='og:image' content='' />

      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:url' content='' />
      <meta property='twitter:title' content='Elettronica e automazione industriale | Elettronica scarpa' />
      <meta
        property='twitter:description'
        content='Ci occupiamo di elettronica e automazione industriale applicata a vari settori produttivi (tessile, meccanotessile, metalmeccanico, fondiario, packaging, trattamento acque e così via), e fino ad oggi contiamo più di 2000 installazioni in tutto il mondo.'
      />
      <meta property='twitter:image' content='' />
    </Helmet>

    <Herobanner large={true}>
      <h1 className='mb-6'>
        Elettronica e<br />
        automazione industriale
      </h1>
      <Button to={"/#contatti"}>Richiedi un contatto</Button>
    </Herobanner>
    <Container notAligned={true}>
      <Box>
        <div className='row'>
          <div className='col-12 col-lg-10 offset-lg-1'>
            <IconMiniTitle>Chi siamo</IconMiniTitle>
            <h3 className='my-4'>Benvenuto nel futuro.</h3>
            <p>
              Un futuro tecnologico, agile e in rapida evoluzione. Un futuro all’insegna della quarta rivoluzione industriale, che dà vita a sfide cruciali per le nostre imprese: l’adozione di
              tecnologie produttive fortemente innovative, la digitalizzazione diffusa che crea nuovi modelli di consumo, l’elettrificazione dei sistemi. Sei pronto a farne parte?
            </p>
            <img className='w-100 mt-6' alt='homepage' src='/image/homepage-1.jpg' />
          </div>
        </div>
      </Box>
    </Container>
    <Container customClass='py-2' />
    <Container customClass='py-10'>
      <div className='col-10 offset-1 col-md-12 offset-md-0'>
        <IconMiniTitle>I nostri punti di forza</IconMiniTitle>
        <h3 className='mt-4 mb-9'>Tre motivi per collaborare con noi. </h3>
        <IconsListOrizontal>
          <IconsListOrizontalIcon title={"Prodotti certificati"} icon={"qualita"}>
            Puntiamo alla perfezione: i nostri prodotti vengono sottoposti a rigidi protocolli per garantire la massima qualità.
          </IconsListOrizontalIcon>
          <IconsListOrizontalIcon title={"Supporto tecnico"} icon={"supporto"}>
            Non siamo dei semplici fornitori. Vorremmo diventare il tuo partner tecnologico: puoi contare su di noi, sempre.
          </IconsListOrizontalIcon>
          <IconsListOrizontalIcon title={"Aggiornamento costante"} icon={"persone"}>
            Dietro a ogni prodotto c’è un gruppo di persone brillanti, sempre in aggiornamento, che guarda dritto al futuro.
          </IconsListOrizontalIcon>
        </IconsListOrizontal>
      </div>
    </Container>
    <Container customClass='py-2' />
    <ImageFull image={"/image/homepage-2.jpg"}>
      <h3 className='text-center'>
        L’elettronica:
        <br />
        una passione che ci guida da oltre 40 anni, generazione dopo generazione.{" "}
      </h3>
    </ImageFull>
    <Container customClass='' />
    <Container customClass='py-10 e-containers__container--servizi' idSection='servizi'>
      <div className='col-10 offset-1 col-md-12 offset-md-0'>
        <IconMiniTitle>I nostri servizi e prodotti</IconMiniTitle>
        <h3 className='mt-4 mb-9'>Cosa possiamo fare per te? </h3>
        <IconsListVertical>
          <IconsListVerticalIcon to={"/progettazione"} title='Progettazione' icon='progettazione'>
            Gestiamo in completa autonomia e con estrema cura tutte le fasi di progettazione, sia software che hardware.
          </IconsListVerticalIcon>
          <IconsListVerticalIcon to={"/software"} title='Software Engineering' icon='software'>
            Sviluppiamo applicazioni per i più importanti marchi dei PLC. Abbiamo esperienza anche in motion control e robotica.
          </IconsListVerticalIcon>
          <IconsListVerticalIcon to={"/quadri-elettrici"} title='Realizzazione quadri elettrici e impianti' icon='realizzazione'>
            Quadri elettrici inox, verniciati o in vetroresina, ma non solo: realizziamo interi impianti su specifica del cliente.
          </IconsListVerticalIcon>
          <IconsListVerticalIcon to={"/retrofitting"} title='Retrofitting' icon='retrofitting'>
            Se c’è bisogno di aggiornare o rinnovare l’impianto, puoi contare su di noi e sulla nostra grande esperienza.
          </IconsListVerticalIcon>
          <IconsListVerticalIcon to={"/teleassistenza"} title='Teleassistenza da remoto' icon='teleassistenza'>
            I nostri tecnici sono disponibili a supportarti anche da remoto. Offriamo teleassistenza sia in Italia che all’estero.
          </IconsListVerticalIcon>
        </IconsListVertical>
      </div>
    </Container>
    <Container customClass='' />
    {/* Temp */}
    <ContactContainer />
    <Container notAligned={true}>
      <Box>
        <div className='row'>
          <div className='col-12 col-lg-10 offset-lg-1'>
            <IconMiniTitle>Galleria fotografica</IconMiniTitle>
            <BoxImageSlider title={"Scopri di più su di noi"} customClass='mt-4'>
              <img className='w-100 mt-6' alt='homepage' src='/image/slider/1.jpg' />
              <img className='w-100 mt-6' alt='homepage' src='/image/slider/2.jpg' />
              <img className='w-100 mt-6' alt='homepage' src='/image/slider/3.jpg' />
              <img className='w-100 mt-6' alt='homepage' src='/image/slider/4.jpg' />
              <img className='w-100 mt-6' alt='homepage' src='/image/slider/5.jpg' />
              <img className='w-100 mt-6' alt='homepage' src='/image/slider/6.jpg' />
            </BoxImageSlider>
          </div>
        </div>
      </Box>
    </Container>
  </Layout>
);

export default IndexPage;
