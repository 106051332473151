import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const IconsListVerticalIcon = ({children, to, title, icon = "", shadow = false}) => (
  <div className='col-12 col-md-6 col-lg-4'>
    <AniLink paintDrip hex='#ad151e' duration={0.7} bg='#ad151e' to={to} className={`c-icons-list-v__single-container ${shadow === true ? "active" : ""}`}>
      <div className={`c-icons-list-v__single-icon ${icon}`} />
      <div className='c-icons-list-v__single-title'>
        <h4>{title}</h4>
      </div>
      <div className='c-icons-list-v__single-text'>{children}</div>
    </AniLink>
  </div>
);

export default IconsListVerticalIcon;
